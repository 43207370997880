import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  media: {
    height: 0,
    paddingTop: "80%",
  },
  imageFullscreen: {
    width: "100%",
  },
  fullscreenBox: {
    padding: 0,
  },
  modalNoBorder: {
    boxShadow: "none",
    border: "none",
  },
}));

export default function GalleryItem({ galleryItem, ...props }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
  };

  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.media}
        image={galleryItem.src}
        onClick={handleOpen}
      />
      <Modal
        open={open}
        onClick={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalNoBorder}
      >
        <Grid container justifyContent="center">
          <Grid item xs={6} className={classes.fullscreenBox}>
            <Box sx={style}>
              <img
                src={galleryItem.src}
                className={classes.imageFullscreen}
                alt=" "
              />
            </Box>
          </Grid>
        </Grid>
      </Modal>
    </Card>
  );
}
