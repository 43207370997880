import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";

import GalleryItem from "./GalleryItem";
import GalleryItemSkeleton from "./skeletons/GalleryItemSkeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  media: {
    height: 0,
    paddingTop: "80%",
  },
  galleryItemContent: {
    height: "5em",
  },
  galleryHeader: {
    marginBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function Gallery({ data, isLoading, ...props }) {
  const classes = useStyles();

  return (
    <Grid item container xs={12}>
      <Grid item container xs={12} className={classes.galleryHeader}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <h2>Galer&iacute;a</h2>
          </Paper>
        </Grid>
      </Grid>
      {/* TODO: Show gallery items */}
      <Grid item container xs={12} justifyContent="center">
        <Grid item container xs={12} justifyContent="center">
          <Grid item container spacing={3} xs={8}>
            {isLoading ? (
              <React.Fragment>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <GalleryItemSkeleton />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <GalleryItemSkeleton />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <GalleryItemSkeleton />
                </Grid>
              </React.Fragment>
            ) : (
              data.gallery.map((galleryItem, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  key={`gallery-item-${index}`}
                >
                  <GalleryItem galleryItem={galleryItem} />
                </Grid>
              ))
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
