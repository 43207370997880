import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Forum from "@material-ui/icons/Forum";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
    zIndex: 99,
    boxShadow: "0px 0px 43px 0px rgba(0,0,0,0.5)",
  },
  media: {
    height: 200,
    width: "auto",
    margin: "auto",
  },
  whiteText: {
    color: "white",
  },
  contactCardLine: {
    marginTop: 0,
    marginBottom: 0,
  },
  anchorNoDecoration: {
    textDecoration: "none",
    display: "block",
    margin: "auto auto",
  },
  floatRight: {
    float: "right",
  },
  hidden: {
    display: "none",
  },
});

export default function ContactSection({ data, isLoading, ...otherProps }) {
  const classes = useStyles();
  const INSTAGRAM_PROFILE_URL = data && data.instagram_url;
  const WHATSAPP_WEB_URL = data && data.whatsapp_url;

  const [isVisible, setIsVisible] = useState(true);

  const hideCardHandler = () => {
    setIsVisible(false);
  };

  const openExternalLink = (targetURL) => {
    return () => window.open(targetURL, "_blank");
  };

  return (
    <Grid container spacing={1} justifyContent="space-around">
      {isLoading ? (
        <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
      ) : (
        <Grid item xs={4} sm={4} md={4} lg={4}>
          <Slide in={isVisible} direction="left" mountOnEnter unmountOnExit>
            <Card className={classes.root} variant="outlined">
              <Button className={classes.floatRight} onClick={hideCardHandler}>
                <CloseIcon color="error" />
              </Button>
              {/* <Fab onClick={hideCardHandler}>
                <CloseIcon color="error" />
              </Fab> */}
              <CardContent>
                <CardMedia
                  className={classes.media}
                  image="/lbd_logo_no_shadow.png"
                  title="Bakery's Logo"
                  component="img"
                />
                <Typography gutterBottom variant="h5" component="h2">
                  Lulitas By Dulcinea
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {data.profile_description.split("\n").map((item, index) => (
                    <p
                      key={`bio-p-${index}`}
                      className={classes.contactCardLine}
                    >
                      {item}
                    </p>
                  ))}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  color="secondary"
                  onClick={openExternalLink(INSTAGRAM_PROFILE_URL)}
                >
                  <FavoriteIcon />
                </Button>
                <Button
                  size="small"
                  color="primary"
                  onClick={openExternalLink(WHATSAPP_WEB_URL)}
                >
                  <Forum />
                </Button>
              </CardActions>
            </Card>
          </Slide>
        </Grid>
      )}
    </Grid>
  );
}
