import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { getData } from "./lib/utils";
import HeroBackground from "./components/HeroBackground";
import ContactSection from "./components/ContactSection";
import Gallery from "./components/Gallery";

const useStyles = makeStyles((theme) => ({
  backgroundClass: {
    background: "url(background_pattern.png) repeat",
    maxWidth: "100%",
    overflow: "hidden",
  },
}));

export default function CenteredGrid() {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    setIsLoading(true);

    const fetchCachedResponse = async () => {
      // get data from helper
      const data = await getData();
      console.log(data);
      // set data
      setData(data);
      // set loading flag to false; data already set
      setIsLoading(false);
    };

    setTimeout(() => {
      // call async side effect to fetch data on Airtable
      fetchCachedResponse();
    }, 1000 * 3);
  }, []);

  return (
    <div className={classes.backgroundClass}>
      {/* {isLoading ? (
        <LogoImage />
      ) : ( */}
      <Grid container justifyContent="space-around">
        <Grid item xs={12}>
          <HeroBackground data={data} isLoading={isLoading} />
        </Grid>
        {/* <Grid item xs={12}>
          <SocialCounts data={data} isLoading={isLoading} />
        </Grid> */}
        <Grid item xs={12}>
          <ContactSection data={data} isLoading={isLoading} />
        </Grid>{" "}
        <Grid item xs={12}>
          <Gallery data={data} isLoading={isLoading} />
        </Grid>
        <Grid item xs={12}>
          {/* <SocialCounts data={data} isLoading={isLoading} /> */}
        </Grid>
      </Grid>
      {/* )} */}
      <Box pt={isLoading ? 100 : 10}></Box>
    </div>
  );
}
