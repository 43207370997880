import axios from "axios";

export const getSocialCounts = (data, isLoading) => {
  const likeAggregator = (previousValue, currentValue, currentIndex, array) => {
    return previousValue + currentValue.data.node.edge_liked_by.count;
  };
  /*
  const commentAggregator = (
    previousValue,
    currentValue,
    currentIndex,
    array
  ) => {
    return previousValue + currentValue.data.node.edge_media_to_comment.count;
  };
  */

  if (isLoading) return {};

  return {
    galleryLikeCount: data.gallery.reduce(likeAggregator, 0),
    monthlyPostCount: data.misc_statistics.postsThisMonth,
    followedBy: data.misc_statistics.followersCount,
  };
};

export const stripHashtags = (text) => {
  return text.substring(0, text.indexOf("#"));
};

const getAttachmentSources = (attachments, single) => {
  if (attachments.length === 0 || !attachments) {
    return null;
  }

  if (single) {
    return { src: attachments[0].url };
  } else {
    return attachments.map((item) => {
      return {
        src: item.url,
      };
    });
  }
};

export const getData = async () => {
  const baseUrl = `https://api.airtable.com/v0/${process.env.REACT_APP_TARGET_BASE_ID}/${process.env.REACT_APP_TARGET_TABLE_ID}`;
  try {
    const response = await axios.get(baseUrl, {
      params: {
        maxRecords: 1,
        // sort: [{ field: "createdAt", direction: "desc" }],
        "sort[0][field]": "createdAt",
        "sort[0][direction]": "desc",
      },
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`,
      },
    });

    return response.data.records
      ? response.data.records.map((item) => {
          const parsedItem = item.fields;

          parsedItem.background_image = getAttachmentSources(
            parsedItem.background_image,
            true
          );
          parsedItem.gallery = getAttachmentSources(parsedItem.gallery, false);

          return parsedItem;
        })[0]
      : null;
  } catch (err) {
    console.error(err);
  }
};
