import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "80%",
  },
  galleryItemContent: {
    height: "5em",
    overflow: "hidden",
  },
  galleryHeader: {
    marginBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function GalleryItemSkeleton({ data, isLoading, ...props }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <Skeleton animation="wave" variant="rect" className={classes.media} />

      <CardContent>
        <React.Fragment>
          <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
          <Skeleton animation="wave" height={10} width="80%" />
        </React.Fragment>
      </CardContent>
    </Card>
  );
}
