import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  logoBigImg: {
    background: "none",
    maxHeight: 375,
    maxWidth: "100%",
    textShadow: "2px 2px 4px #000000",
  },
}));

const LogoImage = () => {
  const classes = useStyles();
  const isExtraSmall = useMediaQuery("(max-width:600px)");

  return (
    <img
      className={classes.logoBigImg}
      src={isExtraSmall ? "/lbd_logo_no_shadow.png" : "/lbd_logo.png"}
      alt="LBD's Logo"
    />
  );
};

export default LogoImage;
