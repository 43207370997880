import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import LogoImage from "./LogoImage";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "80vh",
    overflow: "hidden",
  },
  backgroundImg: {
    zIndex: -1,
    display: "block",
    objectFit: "cover",
    width: "100%",
    maxHeight: 800,
  },
  logoBigImg: {
    background: "none",
    maxHeight: 375,
    maxWidth: "100%",
    textShadow: "2px 2px 4px #000000",
  },
}));

const HeroBackground = ({ data, isLoading, ...props }) => {
  const classes = useStyles();
  const [currentIndex] = useState(0);

  const backgroundImages = [
    {
      id: 0,
      path: isLoading ? null : data.background_image.src,
    },
  ];

  return (
    <React.Fragment>
      {isLoading ? (
        <Grid container>
          <Grid item xs={8}>
            <LogoImage />
          </Grid>
        </Grid>
      ) : (
        <React.Fragment>
          <Box
            sx={{
              position: {
                xs: "relative",
                sm: "absolute",
                md: "absolute",
                lg: "absolute",
                xl: "absolute",
              },
            }}
          >
            <LogoImage />
          </Box>
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "block",
                md: "block",
                lg: "block",
                xl: "block",
              },
              maxHeight: 800,
            }}
          >
            <img
              src={backgroundImages[currentIndex].path}
              alt="holaa"
              className={classes.backgroundImg}
            />
          </Box>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default HeroBackground;
